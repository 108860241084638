import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export const Footer = () => (
  <div>
    <Container fluid style={{ background: "#003465", color: "#ffffff" }}>
      <Row
        style={{ minHeight: "15vh", paddingTop: "16px", paddingBottom: "16px" }}
      >
        <Col
          xs={{ span: 12, order: 4 }}
          md={{ span: 2, order: 1 }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "top",
            marginTop: "16px",
          }}
        >
          <p
            style={{
              margin: "0",
              fontSize: "0.6rem",
            }}
          >
            ©2020 BE1 Consulting
          </p>
        </Col>
        <Col xs={{ span: 12, order: 1 }} md={{ span: 4, order: 2 }} />
        <Col
          xs={{ span: 6, order: 2 }}
          md={{ span: 2, order: 2 }}
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "top",
          }}
        >
          {/* <div>
            <h6>Istanbul, TR</h6>
            <p>
              Büyükdere Caddesi, 193 Plaza,
              <br />
              Kat: 2, No: 193, 1. Levent,
              <br />
              Şişli, Istanbul
            </p>
          </div> */}
        </Col>
        <Col
          xs={{ span: 6, order: 3 }}
          md={{ span: 2, order: 2 }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "top",
          }}
        >
          <div>
            <h6>Bremen, DE</h6>
            <p>Tiefer 15, 28195 Bremen</p>
          </div>
        </Col>
        <Col
          xs={{ span: 6, order: 3 }}
          md={{ span: 2, order: 2 }}
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "top",
          }}
        >
          {/* <div>
            <h6>New Jersey, US</h6>
            <p>NJ 08108</p>
          </div> */}
        </Col>
      </Row>
    </Container>
  </div>
);
