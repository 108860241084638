import React from "react";
import { Container } from "react-bootstrap";
import ReactGA from "react-ga";

function initGA() {
  ReactGA.initialize("UA-55920635-1");
  ReactGA.pageview("about");
}

function About() {
  initGA();
  return (
    <Container
      fluid
      style={{
        minHeight: "75vh",
        display: "flex",
        alignItems: "top",
        justifyContent: "center",
        marginTop: "25vh",
        marginBottom: "50px",
      }}
    >
      <div style={{ maxWidth: "80vw" }}>
        <h1
          style={{
            textAlign: "center",
            padding: "5px",
            borderBottom: "2px solid #c40000",
          }}
        >
          Wer wir sind 
        </h1>
        <p style={{ fontSize: "1.2rem" }}>
        Wir sind ein führendes Forschungs-, Entwicklungs- und Beratungsunternehmen, das mit einer Vielzahl von Kunden zusammengearbeitet hat. Unser Team besteht aus Fachleuten für Informationstechnologien und -dienstleistungen, die über mehr als 25 Jahre Erfahrung in der Branche verfügen. Unser Ziel ist es, die Anforderungen und Bedürfnisse unserer Kunden zu priorisieren und die besten lösungsbasierten Dienstleistungen anzubieten.
          <br />
          <br />
          Wir bieten Lösungen in verschiedenen Projekten aus verschiedenen Branchen, vom Bankwesen bis zur Fertigung. Die Erfahrung unseres Teams ermöglicht es uns, komplizierte Aufgaben wie Unternehmensberatung, architektonisches Design, Softwareentwicklung, Projektmanagement, Systemtests und Verwaltung zu übernehmen.
          <br />
          <br />
          Als BE1 Consulting produzieren wir mit unserem lösungsorientierten Dienstleistungsansatz stets umfassende Softwarelösungen und exzellente Beratungsleistungen. Indem wir gemeinsam mit Kunden und Lösungspartnern ein gemeinsames Projektteam bilden, lokalisieren wir die Bedürfnisse unserer Kunden genau und leisten entscheidende Hilfestellung bei schlüsselfertigen Projekten.
        </p>
      </div>
    </Container>
  );
}

export default About;
