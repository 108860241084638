import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import ReactGA from "react-ga";

import { Title } from "./framework/Title";
import { AiFillPhone, AiOutlineMail } from "react-icons/ai";
//import map from "../image/europe.svg";
import map from "../image/Map-Alm.PNG";

function initGA() {
  ReactGA.initialize("UA-55920635-1");
  ReactGA.pageview("contact");
}

function Contact() {
  initGA();
  return (
    <Container
      fluid
      style={{
        marginTop: "100px",
        minHeight: "100vh",
        paddingLeft: "5vw",
        paddingRight: "5vw",
      }}
    >
      <Title>Kontakt</Title>
      <Row>
        <Col xs={12} md={6}>
          <Image fluid src={map} />
          <br />
        </Col>
        <Col
          xs={12}
          md={6}
          style={{
            display: "flex",
            alignItems: "top",
            justifyContent: "center",
          }}
        >
          <div style={{ margin: "8px" }}>
            {/* <h4>Istanbul Head Office</h4> */}
            <p>
              {/* Büyükdere Caddesi, 193 Plaza,
              <br />
              Kat: 2, No: 193, 1. Levent,
              <br />
              Şişli, Istanbul
              <br />
              <br />
              <a
                href="mailto:info@be1consultancy.com"
                style={{ color: "#003465" }}
              >
                <AiOutlineMail /> info@be1consultancy.com
              </a>
              <br />
              <a href="tel:+902123714617" style={{ color: "#003465" }}>
                <AiFillPhone /> +90 (0) 212 371 46 17
              </a>
              <br /> */}
              <br />
              <h4>Bremen Office</h4>
              <p>Tiefer 15, 28195 Bremen</p>
              <a href="tel:+4915238764430" style={{ color: "#003465" }}>
                <AiFillPhone /> +49 1523 8764430
              </a>
              <br />
              <br />
              {/* <h4>New Jersey Office</h4>
              <p>NJ 08108</p> */}
            </p>
          </div>
          <div style={{ margin: "8 px" }}></div>
        </Col>
      </Row>
    </Container>
  );
}

export default Contact;
