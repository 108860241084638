import React from "react";
import { Header } from "./framework/Home/Header";
// import { About } from "./framework/Home/HomeAbout";
// import { Solutions } from "./framework/Home/HomeSolutions";
// import { Work } from "./framework/Home/HomeWork";
// import { References } from "./framework/Home/References";
import ReactGA from "react-ga";

function initGA() {
  ReactGA.initialize("UA-55920635-1");
  ReactGA.pageview("home");
}

function Home() {
  initGA();
  return (
    <div>
      <Header />
      {/* <About />
      <Solutions />
      <Work />
      <References /> */}
    </div>
  );
}

export default Home;
