import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiFillTwitterSquare,
} from "react-icons/ai";

export const Header = () => (
  <div>
    <div
      className="background"
      style={{
        background:
          " linear-gradient(320deg, rgba(68,255,238,1) 0%, rgba(23,162,184,1) 20%, rgba(0,52,101,1) 70%)",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        height: "80vh",
        position: "relative",
      }}
    >
      <Container
        fluid
        style={{
          display: "flex",
          position: "absolute",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "#ffffff",
        }}
      >
        <Row>
          <Col xs="12">
            <h1>BE1 Consulting</h1>
            <h5>BE1 Consulting UG</h5>
          </Col>
        </Row>
      </Container>
    </div>
    <Container fluid className="social-media">
      <Row>
        <Col
          xs={12}
          style={{
            height: "20vh",
            textAlign: "center",
            position: "relative",
          }}
        >
          <p
            style={{
              color: "#003465!important",
              position: "absolute",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              fontSize: "2rem",
            }}
          >
            <a
              href="https://www.facebook.com/Be1Consultancy/"
              style={{ color: "#003465" }}
            >
              <AiFillFacebook />
            </a>{" "}
            <a
              href="https://www.linkedin.com/company/be1-consulting-ug"
              style={{ color: "#003465" }}
            >
              <AiFillLinkedin />
            </a>{" "}
            <a
              href="https://twitter.com/Be1Consultancy"
              style={{ color: "#003465" }}
            >
              <AiFillTwitterSquare />
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  </div>
);

/*
<Container
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Row>
        <Col
          xs="12"
          style={{
            color: "#fff",
            textAlign: "center",
            fontSize: "8vmin",
          }}
        >
          <p>Hi, I'm Can Yetismis</p>
        </Col>
        <Col
          xs="12"
          style={{
            color: "#fff",
            textAlign: "center",
            fontSize: "3.5vmin",
          }}
        >
          <p>
            I'm a Software Developer and a Computer Science Graduate from the{" "}
            <br />
            University of Nottingham
          </p>
        </Col>
      </Row>
    </Container>
*/
