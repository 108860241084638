import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import { NavBar } from "./components/framework/NavBar";
import Home from "./components/Home";
import About from "./components/About";
import ESS from "./components/Solutions";
import Consulting from "./components/Consulting";
import Training from "./components/training";
import Products from "./components/Products";
import References from "./components/References";
import Contact from "./components/Contact";
import { Footer } from "./components/framework/Footer";
import { NotFound } from "./components/NotFound";

function App() {
  return (
    <React.Fragment className="App">
      <NavBar />
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/ess" component={ESS} />
          <Route path="/consulting" component={Consulting} />
          <Route path="/training" component={Training} />
          <Route path="/products" component={Products} />
          <Route path="/references" component={References} />
          <Route path="/contact" component={Contact} />
          <Route component={NotFound} />
        </Switch>
      </Router>
      <Footer />
    </React.Fragment>
  );
}

export default App;
